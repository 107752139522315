import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'
import { Products } from '../components/Products'

import { productPreviewsFromEdges } from '../transform'

export const query = graphql`
  {
    prismic {
      allUkuleles {
        ...productPreviewEdges
      }
      ukuleles(lang: "en-nz", uid: "ukuleles") {
        title
        title_archive
        title_current
      }
    }
  }
`

const Ukuleles = ({
  data: {
    prismic: { allUkuleles, ukuleles },
  },
}) => {
  const { title, title_archive, title_current } = ukuleles
  const products = productPreviewsFromEdges(allUkuleles.edges)

  return (
    <Layout>
      <SEO title="Ukuleles For Sale" />
      <section className="bg-white px-4 py-8 md:py-12 lg:py-16">
        <div className="container max-w-6xl">
          <h1 className="text-5xl md:text-6xl">{title[0].text}</h1>
          <h2 className="text-3xl mt-8">{title_current[0].text}</h2>
          <div className="-mx-2 md:-mx-4">
            <Products className="mt-8" products={products.filter(({ forSale }) => forSale)} />
          </div>
        </div>
      </section>
      <section className="bg-gray-200 px-4 py-8 md:py-12 lg:py-16">
        <div className="container max-w-6xl">
          <h2 className="text-3xl">{title_archive[0].text}</h2>
          <div className="-mx-2 md:-mx-4">
            <Products className="mt-8" products={products.filter(({ forSale }) => !forSale)} invert />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Ukuleles

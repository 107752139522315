import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'

const Product = ({ description, forSale, image, invert, materials, price, slug, title }) => (
  <div className="w-full lg:w-1/2 px-2 md:px-4 mb-8">
    <Link
      className="flex flex-wrap block cursor-pointer border-2 border-transparent hover:border-gray-300 rounded-md transition-all duration-150"
      to={`/ukuleles/${slug}`}
    >
      <Img
        className="w-full md:w-1/2 lg:w-full rounded-t md:rounded-t-none md:rounded-l lg:rounded-l-none lg:rounded-t"
        fluid={image}
      />
      <div
        className={`${
          invert ? 'bg-white' : 'bg-gray-200'
        } w-full md:w-1/2 lg:w-full pt-3 px-3 md:px-4 lg:pt-4 pb-4 rounded-b md:rounded-b-none md:rounded-r lg:rounded-r-none lg:rounded-b`}
      >
        <h3 className="text-4xl inline-block">{RichText.asText(title)}</h3>
        {materials && <p className="font-heading uppercase mt-2 lg:text-lg">{materials}</p>}
        <div className="mt-2 text-sm lg:text-base">
          <RichText render={description} />
        </div>
        <p className="text-2xl font-semibold font-heading mt-2 uppercase">{forSale ? `$${price}` : 'Sold'}</p>
        <div className="w-24 border-b-4 border-gray-800 inline-block mt-4 rounded"></div>
      </div>
    </Link>
  </div>
)

Product.defaultProps = {
  description: [{ spans: [], text: 'Description coming soon...', type: 'paragraph' }],
  image: '',
  invert: false,
  materials: 's',
  price: '$POA',
  slug: '',
  title: 'Captain Ukulele',
}

export { Product }

import React from 'react'
import { RichText } from 'prismic-reactjs'

import { Product } from './Product'

const Products = ({ className, products }) => (
  <div className={`flex flex-wrap ${className}`}>
    {products.map(({ title, ...props }) => (
      <Product key={RichText.asText(title)} title={title} {...props} />
    ))}
  </div>
)

Products.defaultProps = {
  className: '',
}

export { Products }
